import React from "react";
import Fade from 'react-reveal/Fade';
import halloween from "../Images/halloween.jpg";
import sessiontimes from "../Images/sessiontimes.png";

export default function SessionTimes({ 
    title,
    messageTitle,
    message2Title, 
    message, 
    message2,
    message3,
    childrenPrice,
    studentPrice,
    adultPrice,
    expressPrice,
    table,
    expressTable,
    dark, 
    id 
}) {
  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      
      <div className="section-content">
          <Fade bottom>
            <h1>{title}</h1>
            <br/>
            <p>
            <span>{messageTitle}</span><br/><br/>
                {message}
                <div class="line"/>
                <p><span>Public Session Prices</span></p>
                <div class="sessionPrices">
                    <ul>
                        <li><span>{childrenPrice}</span><br/>Children</li>
                        <li><span>{studentPrice}</span><br/>Student*</li>
                        <li><span>{adultPrice}</span><br/>Adult</li>
                    </ul>
                    <p class="sessionPrices p">*Requires Student ID</p>
                </div>

                
                <div class="line"/>
                <p><span>Express Session Prices</span></p>
                <div class="sessionPrices">
                  <i>Express Sessions are 1-hour sessions held weekdays only. Not available during Public Holidays. Check timetable below (marked as express session)</i>.
                    <ul  style={{width: '200px'}}>
                        <li><span>{expressPrice}</span><br/><p style={{fontSize: '1em', marginTop: '-5px'}}>All Ages</p></li>
                    </ul>
                    <br/>
                    <br/>
                    <br/><br/><br/>
                </div> 
                
                
                <div class="line"/>
                <p><span>Session Times</span><br/></p>
                <i>Times are subject to change. Bookings not required. Just turn up at the session times below!</i><br/>
                <br/>
                <p>
                  
                  {/*
                  <b>School Holidays Saturday 22nd June - Sunday 7th July</b>
                  <table className="innerTable" style={{width: '100%'}}>
                    <tbody>
                      
                      <tr>
                        <td style={{width: '40%'}}></td>
                        <td style={{width: '20%'}}>Morning</td>
                        <td style={{width: '20%'}}>Afternoon</td>
                        <td style={{width: '20%'}}>Evening</td>
                      </tr>

                      <tr>
                        <td><b>Labour Day (Mon 6/5)</b></td>
                        <td>10:30AM - 12:00PM</td>
                        <td>1:00PM - 2:30PM</td>
                        <td>7:00PM - 8:00PM</td>
                      </tr>
                      
                      <tr>
                        <td><b>Christmas Day (Mon 25/12)</b></td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                      </tr>

                      <tr>
                        <td><b>Boxing Day (Tue 26/12)</b></td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                      </tr>

                      <tr>
                        <td><b>New Years Eve (Sun 31/12)</b></td>
                        <td>10.30AM - 12.00PM</td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                      </tr>
                      <tr>
                        <td><b>New Years Day (Mon 1/1)</b></td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                        <td>CLOSED</td>
                      </tr>
  
                    </tbody>
                  </table>
                  */}
                </p>
                

                <div class="expressTable">{expressTable}</div>
                <div class="line"/>
                <span>{message2Title}</span><br/><br/>
                {message2}
                <div class="line"/>
                <h3>{message3}</h3>
            </p>
          </Fade>   
      </div>
    </div>
  );
}