import React from "react";
import Fade from 'react-reveal/Fade';

export default function SectionHockey({ 
    kidsprice,
    kidstime,
    adultsprice,
    adultstime,
    dark, 
    id 
}) {

  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      <div className="section-content">
        <Fade bottom>
            <h1>Play Ice Hockey</h1><br/>   
            <p>
                <span>Kids Hockey</span><br/><br/>
                Learn all the basics to ice hockey so you can go on and play for the gold coast team - The Grizzleys!
                We supply all the gear necessary to learn: Skates, Stick and helmet!<br/>
                

            </p>
            <h3><span>{kidsprice} </span>Per Lesson</h3>
            <p2>
                {kidstime}<br/>
                Includes stick, helmet, pucks and skate hire<br/>
                Multi-passes available at discounted rate
            </p2>
            <div className="line-dark"/>
            <p>
                <span>Adults Hockey</span><br/><br/>
                It is never too late to start, with drop-in hockey, social and competitve leagues available for all ages. 
                To get yourself into the sport it’s best to come down for the adult lesson’s held on Monday nights here at Iceland.<br/>
            </p>
            <h3><span>{adultsprice} </span>Per Lesson</h3>
            <p2>
                {adultstime}<br/>
                Includes stick, helmet, pucks and skate hire<br/>
                Multi-passes available at discounted rate
            </p2>
            <div className="line-dark"/>
        </Fade>
      </div>
    </div>
  );
}