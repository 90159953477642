import React, { Component } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";

import SectionMain from "./Components/SectionMain";
import SessionTimes from "./Components/SessionTimes";
import SectionParties from "./Components/SectionParties";
import TimeTable from "./TimeTable";
import navText from "./navText";
import $ from "jquery";
import Fade from 'react-reveal/Fade';
import SectionLearn from "./Components/SectionLearn";
import SectionHockey from "./Components/SectionHockey";
import SectionLocation from "./Components/SectionLocation";
import ExpressTable from "./ExpressTable";

class App extends Component {

  componentDidMount () {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 0) {
        $('.middle').fadeOut();
      } else {
        $('.middle').fadeIn();
      }
    });

    $( document ).ready(function() {
      $(".Sunday.box").show();
      $("#suBtn").addClass("selected");
  });

    $("#suBtn").click(function(){
      $(".Sunday.box").show();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").addClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#mBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").show();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").addClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#tBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").show();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").addClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#wBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").show();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").addClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#thBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").show();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").addClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#fBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").show();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").addClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#sBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").show();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").addClass("selected");
    });

  }

  render() {
    return (

      <div className="App" style={{overflowY: "scroll"}}>
        <Fade bottom>
          <div className="middle">
            <div class="mouse"/>
          </div>
        </Fade>
        <Navbar/>
        <SectionMain
          messageTitle="COVID-19 Message:"
          message="Due to COVID-19, Iceland Management expects all customers to follow the social distancing recommendations issued by the 
          Queensland Government. We also require customers to use the 'Check-In QLD' app for Contact Tracing requirements. "
          message2="Thank you for your patience and understanding."
          dark={true}
          subtitle={navText}
          id="section1"
        />

        <SessionTimes
          title="Session Times & Prices"
          messageTitle="Information"
          message="Times are subject to change, please call 55399899 to confirm public session times during public holidays. Bookings are only required for groups of 15 or more."
          message2Title="What to Wear"
          message2="Warm clothing (long pants, jumper), socks are a must! Gloves are optional."
          childrenPrice="$18"
          studentPrice="$22"
          adultPrice="$28"
          expressPrice="$17"
          table={TimeTable}
          expressTable={ExpressTable}
          dark={false}
          id="section2"
        />

        <SectionParties
          title="Children's Parties"
          messageTitle="Information"
          message="Iceland offers fun and exciting parties just for kids, all at a very low price! We run children’s parties every Saturday and Sunday,
          and even on school holidays!"
          message2="Please note we only do one party per session (weekends only), so availability is limited. Please call us for more information."
          price="$30"
          dark={true}
          id="section3"
        />
        
        <SectionLearn
          price="$28"
          price2="$35"
          weekdaytime="4.00PM - 5.00PM"
          weekdaytime2="5.00PM - 6.00PM"
          weekendtime="9.30AM - 10.30AM"
          weekendtime2="4.00PM - 5.30PM"
          dark={false}
          id="section4"
        />

        <SectionHockey
          kidsprice="$20"
          kidstime="Tuesday 5.30PM - 6.30PM"
          adultsprice="$25"
          adultstime="Monday 6.00PM - 7.00PM"
          dark={true}
          id="section5"
        />
        <SectionLocation
          dark={true}
          id="section6"
        />
      </div>
    );
  }
}

export default App;